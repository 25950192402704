import React, { useState, useEffect } from "react";

const SearchChamelean = () => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.myfinance.com/embed/inlineMedia_core.js";
    script.async = true;
    script.onload = () => {
      window.initializeInline({ loadInlineCss: true });
    };
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  return (
    <div className="banner_content">
      <div className="custom-container">
        <div
          className="myFinance-widget"
          data-ad-id="ebe461ff-6e71-4484-bf1c-03e43e225e01"
          data-campaign="chameleon-comlink-zip-detected-providers"
        ></div>
      </div>
    </div>
  );
};

export default SearchChamelean;
